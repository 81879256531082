import { useEffect, useCallback, useState } from "react";
import { ProjectDocumentTable } from "../../DataTable";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import {
  selectIsPostProjectButtonVisible,
  selectProjectId,
  selectThemeColor,
} from "../../../redux/selectors/selectors";
import {
  deleteProjectDocument,
  getProjectDocuments,
  uploadProjectDocument,
} from "../../../utils/api";
import {
  buttonGroupProject,
  ModalStyle,
  ProjectDocumentHeaders,
} from "../../../utils/const";
import Dropzone, { Accept } from "react-dropzone";
import InputField from "../../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const style = [
  { width: "100%", height: "30vh", overflowX: "hidden" },
];

const DefaultAddendaRequest = {
  type: null,
  date:null,
  name:null,
  number:""
}

const ProjectDocument = ({ projectType, setBackButton }: any) => {
  const [selectedDocType, setSelectedDocType] = useState<string>("Document");
  const [selectedFiles, setSelectedFiles] = useState<Blob[]>([]);
  const [message, setMessage] = useState("");
  const [rows, setrows] = useState([]);
  const [headers, setHeaders] = useState(ProjectDocumentHeaders);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectAddendaRequest, setSelectAddendaRequest] = useState(DefaultAddendaRequest);
  const [isLoading, setIsLoading] = useState<boolean>(false);



  const color = useSelector(selectThemeColor);
  const projectId = useSelector(selectProjectId);
  const toShowButton = useSelector(selectIsPostProjectButtonVisible);

  useEffect(() => {
    if (projectId && !!rows) {
      setIsLoading(true);
      fetchGetProjectDocsApi("Document");
    }
  }, []);

  const fetchGetProjectDocsApi = (docType: string) => {
    getProjectDocuments("", { projectId, docType }, undefined)
      .then((response) => {
        setrows(response.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSelectedTypes = () : Accept | undefined  =>{
    switch (selectedDocType) {
      case "Photo":
        let acceptPhotos: Accept = {
          "image/jpg": [".jpg"],
          "image/jpeg": [".jpeg"],
          "image/png": [".png"],
        };
        return acceptPhotos;
      case "Addenda":
        return {
          "application/pdf": [".pdf"],
        };
      default:
        return {
          "application/pdf": [".pdf"],
          // "application/msword": [".doc", ".docx"],
          "application/vnd.ms-excel": [".xls", ".xlsx", ".csv"],
        };
    }
  }

  const handleClick = (docType: string, headers: any) => {
    setSelectedDocType(() => docType);
    setSelectAddendaRequest(DefaultAddendaRequest);
    setHeaders(headers);
    setrows([]);
    setIsLoading(true);
    fetchGetProjectDocsApi(docType);
  };

  const handleDelete = (id: number) => {
    setIsLoading(true);
    deleteProjectDocument("", id, undefined)
      .then((response) => {
        if (!(response.status === "OK")) {
          console.error("Failed to create project");
          setMessage("Failed To Upload Project Documents");
        } else {
          setrows([]);
          setMessage("Project Documents Upload Successfully");
          fetchGetProjectDocsApi(selectedDocType);
       
        }
      })
      .catch((error) => {
        console.error("Error creating project:", error);
      });
  };

  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file: Blob) => {
      setSelectedFiles((prevRows) => [...prevRows, file]);
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const upload = (event: any) => {
    const formData = new FormData();

    try {
      selectedFiles.forEach((doc: any, index: number) => {
        console.log('doc', doc)
        formData.append(
          "projectDocuments",
          new Blob([doc], { type: doc.type }),
          doc.name
        );
      });
      
    } catch (error: any) {
      console.log(error);
    }
    const body = {
      projectId: projectId,
      docType: selectedDocType,
      addendaType: selectAddendaRequest.type,
      addendaNumber: selectAddendaRequest.type === 'Other' ? null : selectAddendaRequest.number === '' ? null :  selectAddendaRequest.number,
      addendaDate: selectAddendaRequest.date,
      name:  selectAddendaRequest.type === 'Other' ?  selectAddendaRequest.name : null
    };
    formData.append("projectDocRequest", JSON.stringify(body));
    setIsLoading(true);
    uploadProjectDocument("", undefined, formData)
      .then((response) => {
        if (!(response.status === "OK")) {
          console.error("Failed to create project");
          setMessage("Failed To Upload Project Documents");
          setIsLoading(false);
        } else {
          setrows([]);
          setMessage("Project Documents Upload Successfully");
          if (selectedDocType === "Addenda") {
            setOpenModal(false);
          }

          fetchGetProjectDocsApi(selectedDocType);
        }
        // onUpload();
        setSelectedFiles([]);
      })
      .catch((error) => {
        console.error("Error creating project:", error);
        setIsLoading(false);
        // Handle error
      });
  };

  const handleAddendaRequestChange = (e: any) => {
    const { name , value} =  e.target;
    if (name === "type") {
      setSelectAddendaRequest({
        ...selectAddendaRequest,
        type: value,
        name: null,
        number: "",
      });
    } else {
      setSelectAddendaRequest({
        ...selectAddendaRequest,
        [name]: value,
      });
    }
  };

  const isInValid = (): boolean | undefined => {
    return (
      (selectedFiles && selectedFiles.length === 0) ||
      !selectAddendaRequest.type ||
      selectAddendaRequest.type === "" ||
      (selectAddendaRequest.type === "Other"
        ? selectAddendaRequest.name === null || selectAddendaRequest.name === ""
        : selectAddendaRequest.number === null ||
          selectAddendaRequest.number === "") ||
      selectAddendaRequest.date === null ||
      selectAddendaRequest.date === ""
    );
  };

  return (
    <>
      <div className="row gx-0">
        {buttonGroupProject.map(
          (
            button: { label: string; value: string; headers: any },
            index: number
          ) => {
            if (button.value === "bidInfo" || button.value === "qAndA" ) {
              return <></>;
            } else {
              return (
                <Button
                  key={index}
                  className="col-2 mx-auto btn main-btn ms-5 mb-2 p-1"
                  onClick={() => {
                    handleClick(button.value, button.headers);
                  }}
                  style={{
                    opacity: selectedDocType === button.value ? 1 : 0.7,
                  }}
                >
                  {button.label}
                </Button>
              );
            }
          }
        )}
      </div>
      <div className="container-fluid">
        <div className="row h-25">
          <ProjectDocumentTable
            rows={rows}
            headers={headers}
            style={style}
            handleDelete={handleDelete}
            isEdit={true}
            isLoading={isLoading}
          />
        </div>
        {selectedDocType === "Addenda" && (
          <div
            className="row col-10 mx-auto"
            style={{ position: "fixed", bottom: "80px" }}
          >
            <button
              className="btn col-3 offset-9 mx-auto"
              onClick={() => {
                setSelectAddendaRequest(DefaultAddendaRequest);
                setOpenModal(true);
              }}
              style={{ background: color.darkColor, color: "white" }}
            >
              Add
            </button>
          </div>
        )}
        {toShowButton && selectedDocType !== "Addenda" && (
          <div className="row mt-2 h-100">
            <Dropzone
              onDrop={onDrop}
              multiple={true}
              accept={handleSelectedTypes()}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className:
                        "dropzone col-12 bg-white text-dark  size-sm mx-auto px-1 py-0 w-50",
                    })}
                    style={{
                      overflowX: "scroll",
                      border: `3px dashed ${color.mediumColor}`,
                    }}
                  >
                    <input {...getInputProps()} />
                    <div
                      style={{
                        height: "9vh",
                        overflowX: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      {selectedFiles && selectedFiles.length > 0 ? (
                        selectedFiles.map((file: any, index: number) => {
                          return (
                            <div className="selected-file ms-2" key={index}>
                              {file && file.name}
                            </div>
                          );
                        })
                      ) : (
                        <span className="col-12 mx-auto">
                          Drag and drop file here, or click to select file
                        </span>
                      )}
                    </div>
                  </div>
                  <footer
                    style={{ position: "sticky", bottom: "3px", zIndex: 9999 }}
                  >
                    <aside className="selected-file-wrapper">
                      <button
                        className="btn"
                        disabled={selectedFiles && selectedFiles.length === 0}
                        onClick={upload}
                        style={{ background: color.darkColor, color: "white" }}
                      >
                        Upload
                      </button>
                      <button
                        className="btn ms-2"
                        onClick={() => setSelectedFiles([])}
                        style={{ background: color.darkColor, color: "white" }}
                      >
                        Clear
                      </button>
                    </aside>
                  </footer>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </div>
      <Modal
        id="succes"
        isOpen={openModal}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="col-12 d-flex justify-content-end">
                <FontAwesomeIcon
                  className="pointer"
                  icon={faClose}
                  size="lg"
                  onClick={() => setOpenModal(false)}
                />
              </div>
            </div>
            <div
              className="modal-body mt-1"
              style={{ width: "35vw", height: "45vh", overflow: "hidden" }}
            >
              <div className="row gx-0">
                <FormControl fullWidth>
                  <InputLabel
                    id="select-label"
                    size="small"
                    className="m-3 bg-white"
                  >
                    Select Type
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    id="type"
                    name="type"
                    className="m-3 bg-white"
                    size="small"
                    value={selectAddendaRequest.type}
                    onClick={() =>
                      setSelectAddendaRequest(prevRequest => ({
                        ...prevRequest,
                        name: null,
                        number: "",
                      }))
                    }
                    onChange={handleAddendaRequestChange}
                    required
                  >
                    <MenuItem
                      className="d-block ms-2"
                      value=""
                      key={-1}
                    ></MenuItem>
                    {["Addendum", "Bulletin", "Plan", "Other"].map(
                      (status, index) => (
                        <MenuItem
                          className="d-block ms-2"
                          key={index}
                          value={status}
                        >
                          {status}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="row gx-0">
                <div className="col-5 ms-2">
                  <InputField
                    id="date"
                    name="date"
                    value={selectAddendaRequest.date}
                    className="form-control"
                    type="date"
                    label=""
                    handleChange={handleAddendaRequestChange}
                    minValue={new Date().toISOString().split("T")[0]}
                    required
                  />
                </div>
                <div className="col-6 ms-4">
                  {selectAddendaRequest.type === "Other" ? (
                    <InputField
                      id="name"
                      name="name"
                      value={selectAddendaRequest.name}
                      className="form-control"
                      type="text"
                      label="Name"
                      handleChange={handleAddendaRequestChange}
                      required
                    />
                  ) : (
                    <InputField
                      id="number"
                      name="number"
                      value={selectAddendaRequest.number.replace(/\D/g, "")}
                      className="form-control"
                      type="text"
                      label="Number"
                      handleChange={handleAddendaRequestChange}
                      required
                    />
                  )}
                </div>
              </div>
              <div className="row mt-3 h-100">
                <Dropzone
                  onDrop={onDrop}
                  multiple={true}
                  accept={handleSelectedTypes()}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps({
                          className:
                            "dropzone col-12  bg-white text-dark  size-sm mx-auto px-1 w-75",
                        })}
                        style={{
                          overflowX: "scroll",
                          border: `3px dashed ${color.mediumColor}`,
                        }}
                      >
                        <input {...getInputProps()} />
                        {selectedFiles && selectedFiles.length > 0 ? (
                          selectedFiles.map((file: any, index: number) => {
                            return (
                              <div key={index} className="selected-file ms-2">
                                {file && file.name}
                              </div>
                            );
                          })
                        ) : (
                          <span className="col-12">
                            Drag and drop file here, or click to select file
                          </span>
                        )}
                      </div>
                      <footer
                        style={{
                          position: "sticky",
                          bottom: "10px",
                          zIndex: 9999,
                        }}
                      >
                        <aside className="selected-file-wrapper">
                          <button
                            className="btn"
                            disabled={isInValid()}
                            onClick={upload}
                            style={{
                              background: color.darkColor,
                              color: "white",
                            }}
                          >
                            Upload
                          </button>
                          <button
                            className="btn ms-2"
                            onClick={() => setSelectedFiles([])}
                            style={{
                              background: color.darkColor,
                              color: "white",
                            }}
                          >
                            Clear
                          </button>
                        </aside>
                      </footer>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectDocument;
