import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grow, GrowProps, Snackbar } from '@mui/material'
import React from 'react'

interface Props{
    openSnackBar: boolean;
    message:string
    handleCloseSnackBar?: () => void;
    duration?:number | null | undefined

}

const CustomizedSnackbar:React.FC<Props> = ({openSnackBar,message,handleCloseSnackBar,duration = 5000} : Props) => {

    const action = (
      <FontAwesomeIcon
        icon={faClose}
        size="lg"
        onClick={handleCloseSnackBar}
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          cursor: "pointer",
          width: "24px",
          height: "24px",
          transition: "color 0.3s",
        }}
        aria-label="Close snackbar"
      />
    );

    function GrowTransition(props: GrowProps) {
      return <Grow {...props} />;
    }
    
  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={duration}
      onClose={handleCloseSnackBar}
      message={message}
      action={action}
      TransitionComponent={GrowTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ContentProps={{
        style: {
          backgroundColor: '#323232',
          color: '#fff', 
          width: '100%', 
          maxWidth: '100vw', 
        },
      }}
    />
  )
}
export default CustomizedSnackbar
