import GlobalHeader from "../GlobalHeader";
import { CustomTable } from "../DataTable";
import { Button } from "@mui/material";
import { exploreOpprtunitiesheaderCells } from "../../utils/const";
import { useEffect, useState } from "react";
import { getBidOppBySearch, getExploreOpportunities } from "../../utils/api";
import InputField from "../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilterCircleXmark,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { SearchFilterBidOppType } from "../../utils/types";
import { DataUtil } from "../../utils/dataUtils";
import ActionConfirmModal from "../ActionConfirmModal/actionConfirmModal";

const style = [
  { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "scroll" },
];

const DefaultSearchFiltered = {
  projectName: "",
  dueDateFrom: "",
  dueDateTo: "",
  email: "",
  city: "",
};

const ExploreOpportunities = () => {

  const [exploreOpportunitiesRowData, setExploreOpportunitiesRowData] = useState<any>([]);
  const [search, setSearch] = useState<SearchFilterBidOppType>(DefaultSearchFiltered);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchGetBidsExpApi();
  }, []);

  const fetchGetBidsExpApi = () => {
    getExploreOpportunities(" ", "", undefined)
      .then((resp) => {
        const data = resp.data;
        if (
          resp.data === null ||
          resp.data === new Map() ||
          resp.data === undefined
        ) {
          setExploreOpportunitiesRowData([]);
        } else {
          setExploreOpportunitiesRowData(data);
        }
      })
      .catch((e) => {
        console.log("e error:>> ", e);
        setExploreOpportunitiesRowData([]);
      });
  };

  const handleChange = (event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target;
    // if(name === "dueDateFrom" && (value !== "" || value !== null)){
    //  setIsDueDate(true);
    // }
    if (name === "city") {
      const changedvalue = value.replace(/[^a-zA-Z0-9 ]/g, "")
      setSearch({
        ...search,
        [name]: changedvalue,
      });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

  const handleSearch = () => {
    getBidOppBySearch("", undefined, search).then((resp) => {
      if (resp.data === null || resp.data === new Map()) {
        setExploreOpportunitiesRowData([]);
      } else {
        setExploreOpportunitiesRowData(resp.data);
        // setIsDueDate(false);
      }
    });
  };
  const handleCheckField = () => {
    return (search.projectName === "" && search.city === "" && (search.dueDateFrom === ""
      || search.dueDateTo === ""));
  };

  return (
    <>
      <div
        className="d-flex justify-content-start mb-2"
        style={{ background: "#19255c" }}
      >
        <img
          src="BW logo-blue bg-svg.svg"
          width="310"
          height="56"
          alt="Login page"
          onError={(e) => {
            const img = e.target as HTMLImageElement;
            img.src = "image-not-found.png";
          }}
        />
      </div>
      <GlobalHeader title="Bid Opportunities" isLogoVisible />
      {/* Explore Opportunities Search */}
      <div className="container-fluid">
        <div className="row gx-0 gap-2">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <InputField
              id="projectName"
              name="projectName"
              type="text"
              className="form-control"
              label="Project"
              value={search.projectName}
              handleChange={handleChange}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <InputField
              id="dueDateFrom"
              name="dueDateFrom"
              type="date"
              className="form-control"
              label="Bid Date (From)"
              value={search.dueDateFrom}
              handleChange={handleChange}
              shrink
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <InputField
              id="dueDateTo"
              name="dueDateTo"
              type="date"
              className="form-control"
              label="Bid Date (To)"
              value={search.dueDateTo}
              handleChange={handleChange}
              disabled={
                search.dueDateFrom === null || search.dueDateFrom === ""
              }
              shrink
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <InputField
              id="city"
              name="city"
              type="text"
              className="form-control"
              label="City"
              value={search.city}
              handleChange={handleChange}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <Button
              className="btn mt-1 me-1"
              onClick={() => {
                setSearch(DefaultSearchFiltered);
                fetchGetBidsExpApi();
              }}
              title="Clear"
            >
              <FontAwesomeIcon icon={faFilterCircleXmark} size="lg" />
            </Button>
            <Button
              className="btn mt-1"
              onClick={handleSearch}
              disabled={
                DataUtil.isObjectsAreEqual(search, DefaultSearchFiltered) || (search.dueDateFrom !== "" && search.dueDateTo === "")
              }
              title="Search"
            >
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </Button>
          </div>
        </div>
        <div className="container-fluid gx-0 mt-2">
          <CustomTable
            headCells={exploreOpprtunitiesheaderCells}
            rows={exploreOpportunitiesRowData}
            style={style}
            onSelectRowClick={() => setOpenModal(true)}
          />
        </div>
      </div>
      <ActionConfirmModal
        isOpen={openModal}
        title=""
        message={`To see the full project, please  <a href="/#login">Sign In</a>`}
        handleClose={() => setOpenModal(false)}
        handleConfirm={() => null}
        toShowFooter={false}
        style={{ width: "19vw", height: "12vh" }}
      />
    </>
  );
};

export default ExploreOpportunities;
