import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTable } from "../DataTable";
import { useEffect, useState } from "react";

const style = [
  { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "scroll" },
];
interface Props {
  file: File;
  headers?: any[];
  rows?: any[];
}

const FilePreview = ({ file, headers=[], rows=[], ...props }: Props) => {

  const [url, setUrl] = useState('');

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [file]);
  
  try {
    switch (file.type) {
      case "application/pdf":
        return (
          <embed
            src={url}
            type="application/pdf"
            width="1200px"
            height="600px"
            aria-label="PDF Document"
            title="PDF Viewer"
            onLoad={(e) => {
              console.log('first', e)
            }
            
            }

          />
        );
      case "image/png":
      case "image/jpeg":
        return (
          <img
            src={url}
            alt="modalImage"
            style={{
              maxWidth: "600",
              maxHeight: "575px",
              display: "flex",
              justifyContent: "center",
            }}
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.src = "image-not-found.png";
            }}
          />
        );
      case "application/csv":
        return (
          <div className="container d-flex justify-content-center w-100">
            <div className="mt-5 h-100" style={{overflow:"auto"}} >
              <CustomTable headCells={headers} rows={rows} style={style} />
            </div>
          </div>
        );
     
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <iframe
            width="100%"
            height="100%"
            title="DOCX Viewer"
            style={{ border: "none" }}
          />
        );

      default:
        return (
          <div style={{ width: "575px", height: "90%" }}>
            <div className="row col-12 mt-1">
              <FontAwesomeIcon
                className="text-danger"
                icon={faWarning}
                color="warning"
                size="8x"
              ></FontAwesomeIcon>
            </div>
            <div className="row col-12 mt-2 ms-1">
              <p className="opacity-50 fs-5">
                <span className="d-flex justify-content-center gx-5">
                  Invalid file.
                </span>
                <span className="d-flex justify-content-center">
                  Please select a valid file.
                </span>
              </p>
            </div>
          </div>
        );
    }
  } catch (error) {
    return (
      <img
        alt="modalImage"
        style={{ maxWidth: "575px", maxHeight: "575px" }}
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          img.src = "image-not-found.png";
        }}
      />
    );
  }
};

export default FilePreview;
