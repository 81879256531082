import { Button, CircularProgress } from "@mui/material";

interface Props {
  isLoading: boolean;
  className: string;
  label: string;
  disabled?:boolean;
  handleClick?:()=> void;
}
const LoaderButton = ({ className, isLoading, label,disabled=false,handleClick=()=> null}: Props) => {
  return (
    <Button
      type="submit"
      className={className}
      onClick={(e : any) => {
        e.preventDefault();
        handleClick();
      }}
      disabled={disabled}
    >
      {isLoading ? (
        <CircularProgress
          className="ms-1"
          color="inherit"
          variant="indeterminate"
          thickness={2}
          size={26}
        />
      ) : (
        label
      )}
    </Button>
  );
};

export default LoaderButton;
