import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead,  TableRow,Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { DocHeadersForQA, queFromSubsHeaders } from '../../../utils/const';
import { selectProjectId, selectThemeColor } from '../../../redux/selectors/selectors';
import { useSelector } from 'react-redux';
import Modal from "react-modal";
import { faClose, faEye, faMinus, faPaperPlane, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubsQueRow } from '../../../utils/types';
import { getQuestionFromSub, askQuestionEmail} from '../../../utils/api';
import { ProjectDocumentTable } from '../../DataTable';
import InputField from '../../InputField';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Loader from '../../Loader';
import CustomTablePagination from '../../DataTable/customTablePagination';
import { VisuallyHiddenInput } from '../../../customeTheme';
import SelectInputField from '../../SelectInputField/selectInputField';
import AttachFileList from '../../AttachFileList/attachFileList';
import { DataUtil } from '../../../utils/dataUtils';
import CustomTooltipOverlay from '../../CustomTooltipOverlay/customTooltipOverlay';

interface Props{
    isOpen:boolean;
    isSender: boolean;
    handleClose?: () => void;
    handleCallQuetionSubList?: (event : any) => void;
    rowData: SubsQueRow[];
    projectName:string;
}

type docInfo = {
    docId:number,
    docName:string,
    date:string,
    size:string,
    fileDataBase64:string,
}

type answer = {
    answer:string,
    files: {
        name: string;
        files: File[];
      }[];
}

type QuestionAndAns = {
  ansAndSubQue: QuestionDetail[],
  status: string,
  sector: string,
}

const defaultQuestionAndAns = {
  ansAndSubQue: [],
  status: "",
  sector: "",
}

type QuestionDetail = {
    id: number,
    sender:string,
    receiver:string,
    message: string,
    docs: docInfo[],
    receivedTime: string,
    action: string
}
const QuestionFromSubs = ({
    isOpen,
    isSender,
    handleClose,
    rowData,
    handleCallQuetionSubList,
    projectName
}:Props) => {

    const color = useSelector(selectThemeColor);
    const projectId = useSelector(selectProjectId);
    const [expandedRow, setExpandedRow] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [queDetails, setQueDetails] = useState<QuestionAndAns>(defaultQuestionAndAns);
    const [answer, setAnswer] = useState<answer>({
        answer:"",
        files:[],
    });
    const [docModal, setDocModal] = useState<boolean>(false);
    const [docRowData, setDocRowData] = useState<docInfo[]>([]);
    const [viewDocSenderName, setViewDocSenderName] = useState<string>("");


    type Status = "New" | "Pending"; // Add more statuses as needed

    const colors: Record<Status | "Default", string> = {
        New: "#F70101",
        Pending: "#FF8C00",
        Default: color.mediumColor
    };

    const displayStatus: Record<Status | "Answered", string> = {
      New: "Pending",
      Pending: "Viewed",
      Answered: "Answered",
    };

    const handleChange = (event: any) => {
      const { value, name } = event.target;
      console.log(event.target);
      setAnswer({
        ...answer,
        answer: value,
      });
    };

    const handleExpandRow = (row: any) =>{
      if (expandedRow === row.id) {
        setExpandedRow(0);
        setAnswer({
          answer:"",
          files:[],
      });
        setQueDetails(defaultQuestionAndAns);
        // setAnsDetails(defaultQueDetail);
        setSelectedFiles([]);
        return;
      }else{
        setExpandedRow(row.id);
        const id = row.id;
        if (queDetails === defaultQuestionAndAns) {
          setIsLoading(true);
          getQuestionFromSub("", { id, invited:isSender }, undefined)
            .then((resp) => {
              if (resp.status === "OK") {
                console.log(resp.data, "d");
                const qnA = DataUtil.isArrayAndNotEmpty(resp.data.ansAndSubQue) ? resp.data.ansAndSubQue.map((que: any) => ({
                  id: que.id,
                  sender: que.sender,
                  receiver: que.receiver,
                  message: que.message,
                  docs: que.docs || [],
                  receivedTime: que.receivedTime,
                  action: "viewAndDownload",
                })) : [];
                console.log(qnA, "qna");
                setQueDetails({
                  ...resp.data,
                  ansAndSubQue: qnA,
                });
                console.log(queDetails, "det");
              }
            })
            .catch((error) => {
              console.log("Error fetching question details", error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    }

    const handleSetAttachMent = (files: FileList | null | File[]) => {
        let fileArray: {
          name: string;
          files: File[];
        }[] = [];
    
        if (files) {
          Array.from(files).forEach((file: any) => {
            fileArray.push({
              name: file.name,
              files: Array.from(files),
            });
          });
        }
        return fileArray;
      };
    
      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const files = event.target.files;
        const newFiles = handleSetAttachMent(files);
        console.log("files", files);
        if (files) {
            setAnswer({
                ...answer,
                files: handleSetAttachMent(files) ,
              });
          setSelectedFiles((prevRows: any) => [...prevRows, ...handleSetAttachMent(files)]);
        }
      };
    
      const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.currentTarget.value = "";
      };
    
      const handleRemoveSelectedFile = (index: number) => {
        let removeSelectedFiles = [...selectedFiles].filter(
          (file: File, fileIndex : number) => fileIndex !== index
        );
        setSelectedFiles(removeSelectedFiles);
        setAnswer({
          ...answer,
          files: handleSetAttachMent(removeSelectedFiles),
        });
      };

      const handleSubmitAnswered = ({answer}: any) => {
        setIsLoading(true);
        const formData = new FormData();
        const communicationId = queDetails.ansAndSubQue[0].id;
        const sector = queDetails.sector;
        const status = isSender ? "Pending" : "Answered";
        const ans= answer.answer;
        const body = { emailBody:ans, projectId ,communicationId, status, sector};
        console.log(body,"body");
        formData.append("request", JSON.stringify(body));
        answer.files.forEach((file: any, index:number) => {
          const {blob,name} = DataUtil.createNewBlocAndFileName(file, index);
          formData.append("qNaAttachments", blob, name);
        });
        console.log("body que mail", formData);
        askQuestionEmail("",undefined,formData)
        .then((resp)=>{
          if(resp.status === 'OK'){
              const id = queDetails.ansAndSubQue[0].id;
              const invited = isSender ? true : false;
              setIsLoading(true);
              getQuestionFromSub("", {id,invited}, undefined)
              .then((resp)=>{
                  if(resp.status === 'OK'){
                      console.log(resp,"d");
                      setQueDetails({
                        ...resp.data,
                        action: "viewAndDownload"
                      });
                      console.log(queDetails.ansAndSubQue,"det");
                      setSelectedFiles([]);
                  }
                  setAnswer({
                    answer:"",
                    files:[],
                });
              })
              .catch((error)=>{
                  console.log("Error fetching question details", error);
              })
              .finally(() => {
                  setIsLoading(false);
                });
          }
        })
      };



      const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
      ) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage); 
        setPage(0);
      }
      const tableRender = ()=>{
        return (
          <>
            <TableContainer style={{ height: "70%" }}>
              <Table
                key="table"
                size="small"
                aria-label="sticky table"
                stickyHeader={true}
              >
                <TableHead className="table-head">
                  <TableRow className="table-row">
                    {queFromSubsHeaders.map((header) => {
                      return header.id === "conventBtn" ? (
                        <></>
                      ) : header.id === "receivedDate" ||
                        header.id === "answeredDate" ? (
                        <TableCell
                          className="col-2"
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            whiteSpace: "nowrap",
                            backgroundColor: color.mediumColor,
                            color: "white",
                          }}
                        >
                          <b className="mt-5">{header.name}</b>
                        </TableCell>
                      ) : (
                        <TableCell
                          className={
                            header.id === "subject" ||
                            header.id === "askedBy" ||
                            header.id === "status"
                              ? "col-2"
                              : "col-1"
                          }
                          style={{
                            backgroundColor: color.mediumColor,
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <b>{header.name}</b>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody
                  className="pointer"
                  style={{
                    tableLayout: "fixed",
                  }}
                >
                  {DataUtil.isArrayAndNotEmpty(rowData) ? (
                    rowData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => (
                        <React.Fragment key={row.id}>
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              height: 30,
                            }}
                            style={{
                              backgroundColor:
                                expandedRow === row.id ? "#f8f9fa" : "white",
                            }}
                            onClick={() => {
                              console.log(row, "row expanded");
                              handleExpandRow(row);
                            }}
                          >
                            <TableCell
                              style={{ textAlign: "center" }}
                              component="th"
                              scope="row"
                            >
                              {row.questionsCount}
                            </TableCell>
                            <TableCell
                              style={{
                                height: "auto",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                textAlign: "center",
                              }}
                            >
                              {row.subject === "" || row.subject === null ? (
                                <FontAwesomeIcon icon={faMinus} />
                              ) : (
                                row.subject
                              )}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {row.askedBy}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {row.attachmentCount}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                                color:
                                  Object.entries(colors).find(
                                    ([key]) => key === row.status
                                  )?.[1] || colors.Default,
                              }}
                            >
                              {isSender
                                ? Object.entries(displayStatus).find(
                                    ([key]) => key === row.status
                                  )?.[1]
                                : row.status}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {row.receivedDate}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {row.answeredDate === "" ||
                              row.answeredDate === null ? (
                                <FontAwesomeIcon icon={faMinus} />
                              ) : (
                                row.answeredDate
                              )}
                            </TableCell>
                            {/* <TableCell></TableCell> */}
                          </TableRow>
                          {expandedRow === row.id && (
                            <TableRow className="table-row">
                              <TableCell
                                className="p-0"
                                colSpan={11}
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                }}
                              >
                                <Accordion
                                  expanded
                                  style={{
                                    maxHeight: "400px",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                    paddingBottom: "0",
                                  }}
                                  className="bg-light notShowScrollBar"
                                >
                                  {isLoading ? (
                                    <Loader
                                      className="d-flex justify-content-center mt-5 py-5"
                                      label="Fetching Details"
                                    />
                                  ) : (
                                    <AccordionDetails>
                                      <div className="row gx-0">
                                        <span className="col-3">
                                          Privacy : {queDetails.sector}
                                        </span>
                                      </div>
                                      <div>
                                        {DataUtil.isArrayAndNotEmpty(
                                          queDetails.ansAndSubQue
                                        ) &&
                                          queDetails.ansAndSubQue.map((ans) => (
                                            <>
                                              <div className="row gx-0 mt-2">
                                                <div className="col-2 d-flex">
                                                  <Avatar
                                                    className="size-xs"
                                                    {...DataUtil.createStringAvatar(
                                                      ans.sender,
                                                      color
                                                    )}
                                                  />
                                                  <span
                                                    className="ms-2 p-1"
                                                    style={
                                                      ans.sender ===
                                                      localStorage.getItem(
                                                        "name"
                                                      )
                                                        ? {
                                                            color:
                                                              color.darkColor,
                                                          }
                                                        : {
                                                            color:
                                                              color.mediumColor,
                                                          }
                                                    }
                                                  >
                                                    {ans.sender}
                                                  </span>
                                                </div>
                                                <div className="col-4 offset-3 d-flex justify-content-center opacity-50 mt-1">
                                                  {ans.receivedTime}
                                                </div>
                                              </div>

                                              <div className="row gx-1">
                                                <div className="col-7 ms-5 py-1 bg-white rounded">
                                                  {ans.message}
                                                </div>
                                                <div className="col-2 ms-1 d-flex justify-content-start">
                                                    <Button
                                                      className="btn w-25"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setDocModal(true);
                                                        setViewDocSenderName(
                                                          ans.sender
                                                        );
                                                        setDocRowData(ans.docs);
                                                      }}
                                                      disabled={
                                                        !DataUtil.isArrayAndNotEmpty(
                                                          ans.docs
                                                        )
                                                      }
                                                      title="View Documents"
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faEye}
                                                        size="lg"
                                                      />
                                                    </Button>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        <div className="row p-1 mt-2">
                                          <div className="col-9">
                                            <InputField
                                              id="answer"
                                              name="answer"
                                              type="text"
                                              className="form-control ms-2"
                                              label="Write Here"
                                              multiline
                                              defaultValue=""
                                              rows={2}
                                              value={answer.answer}
                                              handleChange={handleChange}
                                            />
                                          </div>
                                          <div className="col-2 ms-2 mt-3">
                                            <Button
                                              className="btn main-btn"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleSubmitAnswered({
                                                  answer,
                                                });
                                              }}
                                              disabled={answer.answer === ""}
                                            >
                                              <CustomTooltipOverlay title="Send Message">
                                                <FontAwesomeIcon
                                                  icon={faPaperPlane}
                                                  size="xl"
                                                />
                                              </CustomTooltipOverlay>
                                            </Button>
                                          </div>
                                        </div>
                                        <div className="mt-2">
                                          <Button
                                            className="ms-3 btn"
                                            component="label"
                                            role={undefined}
                                            variant="text"
                                            tabIndex={-1}
                                            size="small"
                                          >
                                            <CustomTooltipOverlay title="Attach files">
                                              <AttachFileIcon
                                                className="fs-4"
                                                color="inherit"
                                              />
                                            </CustomTooltipOverlay>
                                            <VisuallyHiddenInput
                                              multiple
                                              type="file"
                                              onChange={handleFileChange}
                                              onClick={handleFileInputClick}
                                            />
                                          </Button>
                                        </div>
                                        <div className="container">
                                          <AttachFileList
                                            selectedFiles={selectedFiles}
                                            handleRemoveSelectedFile={
                                              handleRemoveSelectedFile
                                            }
                                          />
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  )}
                                </Accordion>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {DataUtil.isArrayAndNotEmpty(rowData) && (
              <CustomTablePagination
                headCells={queFromSubsHeaders}
                rows={rowData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}

            <Modal
              isOpen={docModal}
              className="position-fixed m-2 p-2"
              ariaHideApp={false}
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  width: "50%",
                  height: "400px",
                  padding: "0",
                  backgroundColor: "white",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
                },
              }}
            >
              <div className="row gx-0">
                <div className="col-11">
                  Documents By <strong>{viewDocSenderName}</strong>
                </div>
                <div className="col-1 d-flex justify-content-center pt-2">
                  <FontAwesomeIcon
                    className="pointer"
                    icon={faClose}
                    size="xl"
                    onClick={() => {
                      setDocModal(false);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2" style={{ height: "30%" }}>
                <div style={{ maxHeight: "70%" }}>
                  <ProjectDocumentTable
                    rows={docRowData}
                    headers={DocHeadersForQA}
                    isEdit={false}
                    toShowPagination={false}
                  />
                </div>
              </div>
            </Modal>
          </>
        );
      }



    return !isSender ? (
      <>
        <Modal
          id="submitModal"
          isOpen={isOpen}
          className="position-fixed m-2 p-2"
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "75vh",
              padding: "0",
              backgroundColor: "white",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
            },
          }}
        >
          <div className="row gx-0">
            <div className='col-6 size-2xl fw-bold'>{projectName}</div>
            <div className="col-6 d-flex justify-content-end">
              <FontAwesomeIcon
                className="pointer"
                icon={faClose}
                size="2xl"
                onClick={() => {
                  handleClose && handleClose();
                  setExpandedRow(0);
                  setAnswer({
                    answer: "",
                    files: [],
                  });
                  setQueDetails(defaultQuestionAndAns);
                  setSelectedFiles([]);
                }}
              />
            </div>
          </div>

          <div className="container-fluid">
            <div
              className="table-responsive"
              style={{ height: "76vh", overflowY: "hidden" }}
            >
              {tableRender()}
            </div>
          </div>
        </Modal>
      </>
    ) : (
      isSender && (
        <>
          <div className="container-fluid">
            <div className="row px-0">
              <div className="col-2 offset-10">
                <SelectInputField
                  id="status"
                  name="status"
                  label="Select Status"
                  selectClassName=""
                  menuClassName="d-block ms-2"
                  defaultValue="Posted By Me"
                  menuList={["All Public", "Posted By Me"]}
                  handleChange={handleCallQuetionSubList}
                />
              </div>
            </div>

            <div
              className="table-responsive mt-2"
              style={{ height: "48vh", overflowY: "hidden" }}
            >
              {tableRender()}
            </div>
          </div>
        </>
      )
    );
}

export default QuestionFromSubs;