import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  styled,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useMemo,useState } from "react";
import { InviteBidDetailsInfoType } from "../../../utils/types";
import { CustomTable } from "../../DataTable";
import { addToInviteSubContractorList, getSubmitProjectBidsDetails, sendInviteSubContractor } from "../../../utils/api";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import BidInviteModal from "../../BidInviteModal/bidInviteModal";
import SubmitBidDetailsModal from "../../SubmitBidDetailsModal/submitBidDetailsModal";
import { BidsTableHeaderCells } from "../../../utils/const";
import { DataUtil } from "../../../utils/dataUtils";
import AddSubsToInviteModal from "../../AddSubsToInviteModal/addSubsToInviteModal";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../../redux/selectors/selectors";

interface Props {
  headerCells: any;
  node?: any;
  index?: any;
  refreshSubList?: (message : string) => void;
  handleExpandChange: (index: any) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded:string;
}

const initialBidStatusType = {
  invited: 0,
  viewed: 0,
  bidding:0,
  rejected: 0,
  mayBe: 0,
  noResponse: 0,
  bidsReceive: 0,
}

const SubListDropdown = ({
  node,
  index,
  refreshSubList,
  handleExpandChange,
  expanded = ""
}: Props) => {
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [counterObject, setCounterObject] = useState(initialBidStatusType);
  const [subContractorMails, setSubContractorMails] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openBidInviteModal, setBidOpenInviteModal] = useState<boolean>(false);
  const [openBidViewSubmitBidModal, setOpenBidViewSubmitBidModal] = useState<boolean>(false);
  const [inviteBidDetailsInfo, setInviteBidDetailsInfo] =  useState<InviteBidDetailsInfoType>({bidAmount:0,scope:'',inviteBidDocs:[],tradeIds:[],attachedBidFiles:[], bidTradeCode:node.tradeCode, bidInviteEmail:''})
  const [inviteTypeList, setInviteTypeList] = useState(["Bid Invite"])

  const rows = useMemo(()=>{ return node.length === 0 ? [] : node.subsList},[node]);
  const projectId = useSelector(selectProjectId);

  useEffect(() => {
    setCounterObject({
      viewed: getCount(rows, "Draft") + getCount(rows, "Viewed"),
      invited: getCount(rows, "Invited"),
      bidding: 0,
      rejected: getCount(rows, "Rejected"),
      mayBe: getCount(rows, "May Be"),
      bidsReceive: getCount(rows, "Bid Submitted"),
      noResponse: getNoResponseCount(rows),
    });
  }, [rows]);

  const getCount = (rows: any, status: string) =>
    rows.filter((value: { status: string }) => value.status === status).length;

  const getNoResponseCount = (rows: any) =>
    rows.filter(
      (value: { response: string | null }) =>
        value.response === "N" || value.response === null
    ).length;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      fontWeight: "400",
      padding: "0.7 rem",
      width: 300,
      wordWrap: "break-word",
      overflowX: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  const handleClose = () => {
    setBidOpenInviteModal(false);
    setOpenBidViewSubmitBidModal(false);
    setOpenInviteModal(false);
  };

    const handleSendInvitation= ({subject , emailScope, files, inviteType} : any) => {
      setIsLoading(true);
      const formData = new FormData();
      const body = {
        projectId,
        projectTradeId: node.id,
        subContractorMails: subContractorMails,
        subject,
        emailBody: emailScope,
        inviteType,
      };
      formData.append('emailRequest', JSON.stringify(body));
      files.forEach((file : any, index:number) => {
        const { blob, name } = DataUtil.createNewBlocAndFileName(file, index);
        formData.append("emailAttachments", blob, name);
      });
      if (subContractorMails.length > 0) {
        sendInviteSubContractor("", undefined, formData)
          .then((response) => {
            setIsLoading(false);
            if(response.status === "OK"){
              handleClose();
              refreshSubList!(`The invitation has been successfully sent to the following subcontractors: ${subContractorMails.join(', ')} `);
            }else{
              handleClose();
              setIsOpen(true);
              setMessage(response.message);
            }
          })
          .catch((error: any) => {
            setIsOpen(true);
            handleClose();
            setMessage("Invite has already been sent.");
            setIsLoading(false);
          });
      } else {
        setIsOpen(true);
        setIsLoading(false);
        setMessage("Please Check invite mail");
        handleClose();
      }
    };

    const handlAddToInviteSubList = (subMails: string[]) => {
      setIsLoading(true);
      const body = {
        projectId,
        projectTradeId: node.id,
        subContractorMails: subMails,
      };
      console.log('object', subMails)
      if (subMails.length > 0) {
        addToInviteSubContractorList("", undefined, body)
          .then((response) => {
            setIsLoading(false);
            if(response.status === "OK"){
              handleClose();
              refreshSubList!(`${subMails.join(', ')}  added to list`);
            }else{
              handleClose();
              setIsOpen(true);
              setMessage(response.message);
            }
          })
          .catch((error: any) => {
            setIsOpen(true);
            handleClose();
            setMessage("Invite has already been sent.");
            setIsLoading(false);
          });
      } else {
        setIsOpen(true);
        setIsLoading(false);
        setMessage("Please Check invite mail");
        handleClose();
      }
    };
       
    const handleRowClick = (row: any, actionType: String, check: boolean) => {
      if (actionType === "viewBidDetails") {
        if (row["status"] === "Bid Submitted" || row["status"] === "Draft") {
          getSubmitProjectBidsDetails(
            "",
            { projectBidId: row.bidId, email: row.email },
            undefined
          )
            .then((response) => {
              setIsOpen(true);
              setIsLoading(false);
              if (response.status === "OK") {
                const data = response.data;
                setInviteBidDetailsInfo({
                  bidAmount: data.bidAmount,
                  scope: data.scope,
                  attachedBidFiles: data.attachedBidFiles,
                  tradeIds: [],
                  inviteBidDocs: [],
                  bidTradeCode: node.tradeCode,
                  bidInviteEmail: row.email,
                });
              }
            })
            .catch((error: any) => {
              handleClose();
            });
          setOpenBidViewSubmitBidModal(true);
        }
      } else {
        setSubContractorMails([row.email]);
        setBidOpenInviteModal(true);
        console.log("row", row);
        row["status"] === "Invited"
          ? setInviteTypeList([
              "Second Notice",
              "Addenda",
              "Job Walk",
              "Reminder",
              "Memo",
            ])
          : setInviteTypeList(["Bid Invite"]);
      }
      
    };

  return (
    <>
      <div className="mb-5">
        <Accordion
          key={index}
          expanded={expanded.includes(index)}
          aria-controls="panel1-content"
          id="panel1-header"
          onChange={handleExpandChange(index)}
        >
          <AccordionSummary
            className="mb-2 p-0"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-header"
            id="panel1-header"
            style={{ height: "10vh" }}
          >
            <TableHead key="table-head">
              <TableRow key="row" style={{ zIndex: 1 }}>
                <StyledTableCell key="addSubs">
                  <Button
                    className="btn main-btn"
                    onClick={() => {
                      setOpenInviteModal(true);
                    }}
                  >
                    <label className="size-xs">Add Sub</label>
                  </Button>
                </StyledTableCell>
                <StyledTableCell key="name">
                  <div
                    className="d-block"
                    style={{
                      width: 320,
                    }}
                  >
                    <span className="row text-primary me-1">
                      {node.tradeCode}
                    </span>
                    <span className="row"> {node.name}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="invited">
                  <div className="d-flex flex-column">
                    <span>Invited</span>
                    <span className="ms-2 size-sm">
                      {counterObject.invited}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="viewed">
                  <div className="d-flex flex-column">
                    <span>Viewed</span>
                    <span className="ms-2 size-sm">{counterObject.viewed}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="bidding">
                  <div className="d-flex flex-column">
                    <span>Bidding</span>
                    <span className="ms-2 size-sm">
                      {counterObject.bidding}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="rejected">
                  <div className="d-flex flex-column">
                    <span>Rejected</span>
                    <span className="ms-2 size-sm">
                      {counterObject.rejected}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="mayBe">
                  <div className="d-flex flex-column">
                    <span>May Be</span>
                    <span className="ms-2 size-sm">{counterObject.mayBe}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="noRepsonse">
                  <div className="d-flex flex-column">
                    <span>No Response</span>
                    <span className="ms-2 size-sm">
                      {counterObject.noResponse}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="bidsReceived">
                  <div className="d-flex flex-column">
                    <span >Bids Received</span>
                    <span className="ms-2 size-sm">
                      {counterObject.bidsReceive}
                    </span>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </AccordionSummary>
          <AccordionDetails>
         <CustomTable
              headCells={BidsTableHeaderCells}
              rows={rows}
              onSelectRowClick={handleRowClick}
              onDeleteRowClick={() => null}
              toShowPagination={false}
              maxHeight={150}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <AddSubsToInviteModal
        isOpen={openInviteModal}
        node={node}
        handleClose={handleClose}
        handlAddToInviteSubList={handlAddToInviteSubList}
        isLoading={isLoading}
        invitedsubContractorList={rows}
      />
      <BidInviteModal
        isOpen={openBidInviteModal}
        handleClose={handleClose}
        selectFieldList={subContractorMails}
        handleSubmit={handleSendInvitation}
        isLoading={isLoading}
        tradeId={node.id}
        inviteTypeList={inviteTypeList}
      />
      <SubmitBidDetailsModal
        isOpen={openBidViewSubmitBidModal}
        handleClose={handleClose}
        subMailsList={subContractorMails}
        inviteBidDetailsInfo={inviteBidDetailsInfo}
      />
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handleCloseSnackBar={() => {
          setIsOpen(false);
        }}
        duration={500}
      />
    </>
  );
};

export default SubListDropdown;
