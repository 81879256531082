import { connect } from "react-redux";
import onBoardingForm from "./onBoardingForm";

function mapStateToProps(state: any) {
    const { activeStep } = state.mainReducer;

    const stepActive = activeStep;

    console.log('state from container new Project :>> ', state.projectFormReducers, activeStep);
    return { 
        stepActive
     }
  }
  
  export default connect(mapStateToProps)(onBoardingForm)