import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMemo, useState } from "react";
import { Checkbox, LinearProgress, Paper } from "@mui/material";
import React from "react";
import { setBidInvitationMailList } from "../../redux/actions/projectFormActions";
import CustomTablePagination from "./customTablePagination";
import RowDataTable from "./rowDataTable";
import { StyledTableCell } from "../../customeTheme";
import { DataUtil } from "../../utils/dataUtils";

interface Props {
  headCells: any[];
  rows: any[];
  style?: any;
  maxHeight?: any;
  onSelectRowClick?: (row: any, actionType: string, checked: boolean) => void;
  handleCheck?: (row: any, checked: boolean) => void;
  onClickGetCell?: (cell: any) => void;
  onDeleteRowClick?: (index: any) => void;
  onViewRow?: (index: any) => void;
  handleCheckAll?: (rows: any) => void;
  handleChangeDropDownStatus?: (id: number, status: string) => void;
  handelRemoveTrade?: (id:number, actionTaken: string) => void;
  toShowPagination?:boolean;
  isLoading?:boolean;
}

const CustomTable = ({
  headCells,
  rows,
  onSelectRowClick,
  onClickGetCell = () => {
    return;
  },
  onDeleteRowClick = () => null,
  onViewRow,
  style,
  maxHeight= 440,
  handleCheck,
  handleChangeDropDownStatus,
  handelRemoveTrade,
  toShowPagination=true,
  isLoading=false
}: Props) => {
  const [selectTradeId, setSelectTradeId] = useState<any[]>([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState< readonly number[]>([]);

  const rowCount = useMemo(() => {
    return DataUtil.isArrayAndNotEmpty(rows) ? rows.length : 0;
  }, [rows]);

  const selectedCount = useMemo(() => {
    return DataUtil.isArrayAndNotEmpty(selected) ? selected.length : 0;
  }, [selected]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);    console.log('ev', event.target.value)
    setPage(0);
  };

  const handleStatusChange = (id: any, status: any) => {
    handleChangeDropDownStatus!(id, status);
  };

  const handleSetsubContractorMails = (bidInvitationMailList: string[]) => {
    setBidInvitationMailList(bidInvitationMailList);
  };

  const handleSelectTradeId = (tradeId : any) =>{
    setSelectTradeId(tradeId);
  }


  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows);
      handleCheck!(rows, event.target.checked);
      return;
    }
    handleCheck!([], event.target.checked);
    setSelected([]);
  };

  const handleOnClickCheck = (row: any, checked: boolean) => {
    let newSelected: readonly number[] = [...selected];
    if (checked) {
      newSelected = newSelected.concat(row);
    } else {
      newSelected = newSelected.filter((data: any) => data.email !== row.email);
    }
    setSelected(newSelected);
    handleCheck!(row, checked);
  };

  return (
    <>
      <Paper className="shadow p-0" sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer key="table-container" sx={{ maxHeight: { maxHeight } }}>
          <Table
            key="table"
            className=""
            size="small"
            aria-label="sticky table"
            stickyHeader={true}
          >
            <TableHead key="table-head" style={{ maxHeight: "300px" }}>
              <TableRow key="row" style={{ zIndex: 1 }}>
                {headCells.map((cell) => (
                  <StyledTableCell
                    key={cell.id}
                    style={{
                      width: 300,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {cell.id === "inviteOrgcheck" ? (
                      <Checkbox
                        className="ms-1"
                        color="secondary"
                        indeterminate={
                          selectedCount > 0 && selectedCount < rowCount
                        }
                        checked={rowCount > 0 && selectedCount === rowCount}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                        size="small"
                      />
                    ) : (
                      <span>{cell.name}</span>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              key="body"
              style={{
                tableLayout: "fixed",
              }}
            >
              {rows.length > 0 && !isLoading ? (
                (rowsPerPage > 0 && toShowPagination
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, rowIndex) => (
                  <RowDataTable
                    key={rowIndex}
                    headCells={headCells}
                    row={row}
                    rowIndex={rowIndex}
                    handleSetsubContractorMails={handleSetsubContractorMails}
                    handleSelectTradeId={handleSelectTradeId}
                    selectTradeId={selectTradeId}
                    onSelectRowClick={onSelectRowClick}
                    handleCheck={handleOnClickCheck}
                    onClickGetCell={onClickGetCell}
                    handelRemoveTrade={handelRemoveTrade}
                    onDeleteRowClick={onDeleteRowClick}
                    onViewRow={onViewRow}
                    handleChangeDropDownStatus={handleChangeDropDownStatus}
                    isLoading={isLoading}
                    selected={selected}
                  />
                ))
              ) : isLoading ? (
                <TableRow key={"-1"}>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ textAlign: "center" }}
                  >
                    <LinearProgress style={{ background: "#19255c" }} />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ textAlign: "center" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {toShowPagination && rows.length !== 0 && (
          <CustomTablePagination
            headCells={headCells}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
};

export default CustomTable;
