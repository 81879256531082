import { FormControl, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

interface Props {
  searchItem: string;
  showSearchIconToField?: boolean;
  isLoading?: boolean;
  handleChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleClickOnClear?: () => void;
  showClearIconDisplay: string;
}

const SearchField = ({
  searchItem,
  showSearchIconToField = true,
  showClearIconDisplay,
  isLoading,
  handleChange,
  handleClickOnClear,
}: Props) => {
  return (
    <FormControl className="search-box" fullWidth>
      <TextField
        size="small"
        variant="outlined"
        disabled={isLoading}
        onChange={handleChange}
        value={searchItem}
        placeholder="Search Trade"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              className="pointer"
              position="end"
              style={{ display: showClearIconDisplay }}
              onClick={handleClickOnClear}
            >
              <ClearIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default SearchField;
