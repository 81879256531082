import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Checkbox,
  Stack,
  Chip,
  Link,
  TextField
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputField from "../InputField";
import { useEffect, useState } from "react";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import { getAllSubsMailListForProject } from "../../utils/api";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataUtil } from "../../utils/dataUtils";
import { CustomTable } from "../DataTable";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  selectedMails: string[];
  handleClose: () => void;
  selectFieldList: string[];
  showAllMails?: boolean;
  handleUpdateNewMails?: (newMail: string | string[]) => void;
  isLoading?: boolean;
  handleClickOnPreview?: (viewMailPreview: boolean) => void;
  handleAddSubMails?: (
    addSubMail: {
      mail: string;
      isSelected: boolean;
    }[]
  ) => void;
}

const InviteSubDropdownField = ({
  selectedMails = [],
  handleUpdateNewMails,
  selectFieldList = [],
}: Props) => {
  const [addNewMailsForm, setAddNewMailsForm] = useState({
    isSelected: false,
    newMail: "",
    mailError: "",
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [subMultiMails, setSubMultiMails] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [searchItem, setSearchItem] = useState("");
  const [changeableSelectFieldList, setChangeableSelectFieldList] = useState(selectFieldList);

  useEffect(() => {
    setChangeableSelectFieldList(selectFieldList);
  }, [selectFieldList]);

  const open = Boolean(anchorEl);
  const popOverHeader = [
    {
      id: "cell",
      name: (
        <Checkbox
          className="p-0 ms-1"
          value="All"
          color="secondary"
          checked={isSelectAll}
          onClick={(e: any) => {
            if (!isSelectAll) {
              let filteredSubMails = Array.from(new Set([...selectedMails, ...subMultiMails]));
              handleUpdateNewMails!(filteredSubMails);
            } else {
              let filteredSubMails = [...selectedMails];
              subMultiMails.forEach((checkedEmail) => {
                filteredSubMails = filteredSubMails.filter(
                  (email) => email !== checkedEmail
                );
              });
              handleUpdateNewMails!(filteredSubMails);
            }
          }}
          onChange={() => {
              setIsSelectAll((prev) => !prev);
          }}
        />
      ),
      align: "left",
    },
    { id: "subMail", name: "Sub Mail", action: "", align: "left" },
  ];

  const resetToInitialdList = () =>
    setChangeableSelectFieldList(selectFieldList);

    const removeSpaces = (str: string) => str.replace(/\s+/g, "");


  const searchSelectedTrades = (list: any, query: string) => {
    if (!query || query === "") {
      resetToInitialdList();
    } else {
      const filteredNodes = changeableSelectFieldList.filter((item: any) => {
        const found: boolean = item[1]
          .toLowerCase()
          .includes(removeSpaces(query));
        return found;
      });
      setChangeableSelectFieldList(filteredNodes);
    }
  };

  const handleNewMailFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (value) {
      setAddNewMailsForm((prevState: any) => ({
        ...prevState,
        newMail: value,
      }));
    }
  };

  const handleFetchAllSubOfTradeCode = (
    event: React.MouseEvent<HTMLButtonElement>,
    tradeId: number
  ) => {
    setAnchorEl(event.currentTarget);

    getAllSubsMailListForProject("", tradeId, undefined)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        setSubMultiMails(resp.data.subMailsList);
        setIsSelectAll(
          resp.data.subMailsList.every((subMail: string) =>
            selectedMails.includes(subMail)
          )
        );
      })
      .catch(() => {})
      .finally(() => {});
  };

  const isEmailValidAndNotPresent = (term: string) => {
    if (selectedMails.some((selectedMail) => selectedMail === term)) {
      setAddNewMailsForm((prevState: any) => ({
        ...prevState,
        mailError: "Email Already Exist",
      }));
      return false;
    }
    const isValid = term
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!isValid) {
      setAddNewMailsForm((prevState: any) => ({
        ...prevState,
        mailError: "Invalid Email",
      }));
    } else {
      setAddNewMailsForm((prevState: any) => ({
        ...prevState,
        mailError: "",
      }));
    }
    return isValid;
  };

  const createPopOverRowsStructure = () => {
    return subMultiMails.map((subMail: string, index) => ({
      subMail,
      cell: (
        <Checkbox
          key={index}
          className="p-0"
          value={subMail}
          checked={selectedMails.some(
            (selectedMail) => selectedMail === subMail
          )}
          onChange={() => {
            if (!selectedMails.includes(subMail)) {
              const filteredSubMails = Array.from(
                new Set([...selectedMails, subMail])
              );
              const allElementsPresent = subMultiMails.every((item) =>
                filteredSubMails.includes(item)
              );
              setIsSelectAll(allElementsPresent);
            } else {
              setIsSelectAll(false);
            }
            handleUpdateNewMails!(subMail);
          }}
        />
      ),
    }));
  };

  return (
    <FormControl className="col-12">
      <InputLabel
        id="select-label"
        size="small"
        className="m-3 bg-light"
        style={{}}
      >
        Invite Mail
      </InputLabel>
      <Select
        multiple={true}
        labelId="select-label"
        id="Status"
        name="Status"
        label="Select mail"
        value={selectedMails}
        className="bg-white m-3"
        size="small"
        MenuProps={MenuProps}
        renderValue={(selected) => (
          <Stack gap={2} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() => handleUpdateNewMails!(value)}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                variant="filled"
                color="secondary"
                size="small"
              />
            ))}
          </Stack>
        )}
        input={
          addNewMailsForm.isSelected ? (
            <div className="row m-1 gx-0">
              <InputField
                className="form-control bg-white col-12"
                value={addNewMailsForm.newMail}
                handleChange={handleNewMailFormChange}
                handleKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (
                      e.target.value &&
                      isEmailValidAndNotPresent(e.target.value)
                    ) {
                      setAddNewMailsForm((prevState: any) => ({
                        ...prevState,
                        isSelected: false,
                      }));
                      handleUpdateNewMails!(addNewMailsForm.newMail);
                    }
                  }
                }}
                endAdornment={
                  <InputAdornment className="gap-2" position="end">
                    <FontAwesomeIcon
                      id={addNewMailsForm.newMail}
                      className="pointer"
                      icon={faAdd}
                      size="lg"
                      onClick={(e: any) => {
                        if (
                          e.target.id &&
                          isEmailValidAndNotPresent(e.target.id)
                        ) {
                          setAddNewMailsForm((prevState: any) => ({
                            ...prevState,
                            isSelected: false,
                          }));
                          handleUpdateNewMails!(addNewMailsForm.newMail);
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      className="pointer"
                      icon={faClose}
                      size="lg"
                      onClick={() => {
                        setAddNewMailsForm({
                          newMail: "",
                          isSelected: false,
                          mailError: "",
                        });
                      }}
                    />
                  </InputAdornment>
                }
                id="subMail"
                name="subMail"
                type="text"
                label=""
                error={addNewMailsForm.mailError}
              />
            </div>
          ) : (
            <OutlinedInput label="Status" />
          )
        }
      >
        <div
          className="position-sticky top-0 d-flex justify-content-end z-3"
          style={{ overflow: "hidden" }}
        >
          <FormControl className="p-1 z-3 bg-light" fullWidth>
            <TextField
              size="small"
              variant="outlined"
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setShowClearIcon(event.target.value === "" ? "none" : "flex");
                setSearchItem((prev) => event.target.value);
                searchSelectedTrades(
                  changeableSelectFieldList,
                  event.target.value
                );
              }}
              value={searchItem}
              placeholder="Search Trade"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    className="pointer"
                    position="end"
                    style={{ display: showClearIcon }}
                    onClick={() => {
                      resetToInitialdList();
                      setSearchItem("");
                      setShowClearIcon("none");
                    }}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Button
            className="btn p-0"
            onClick={() => {
              setAddNewMailsForm({
                newMail: "",
                isSelected: true,
                mailError: "",
              });
            }}
          >
            New
          </Button>
        </div>
        <div style={{ overflowY: "auto" }}>
          {DataUtil.isArrayAndNotEmpty(changeableSelectFieldList) &&
            changeableSelectFieldList.map(
              (field: any, index: number) =>
                field[2] !== "0" && (
                  <MenuItem
                    className="d-block ms-4 size-xl p-1"
                    key={index}
                    value={field[0]}
                    title="Click Here"
                    onClick={(e: any) =>
                      handleFetchAllSubOfTradeCode(e, field[0])
                    }
                  >
                    <Link className="text-decoration-none d-flex justify-content-between w-75">
                      <span>{field[1]}</span>
                      <span>{field[2]}</span>
                    </Link>
                  </MenuItem>
                )
            )}
        </div>

        <Popover
          id={open ? "subMails-popover" : undefined}
          open={open}
          anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 200, left: 400 }}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="col-12 d-flex justify-content-end">
            <FontAwesomeIcon className="pointer" icon={faClose} size="2x" onClick={()=> setAnchorEl(null)} />
          </div>
          <div className="container mb-5">
            <CustomTable
              rows={createPopOverRowsStructure()}
              headCells={popOverHeader}
              maxHeight={200}
            />
          </div>
        </Popover>
      </Select>
    </FormControl>
  );
};

export default InviteSubDropdownField;
