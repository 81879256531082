import { faClose, faExpand, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, SelectChangeEvent } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Modal from "react-modal";
import InputField from "../InputField";
import ReactQuill from "react-quill";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProjectId,
  selectThemeColor,
} from "../../redux/selectors/selectors";
import { PreviewModalType, SendSubsInviteType } from "../../utils/types";
import { getInvitePreview } from "../../utils/api";
import SelectInputField from "../SelectInputField/selectInputField";
import { VisuallyHiddenInput } from "../../customeTheme";
import AttachFileList from "../AttachFileList/attachFileList";
import { DataUtil } from "../../utils/dataUtils";
import LoaderButton from "../Loader/loaderButton";
import InviteSubDropdownField from "../InviteSubDropdownField/inviteSubDropdownField";


const InitialSendSubsInvite = {
  inviteType: "Bid Invite",
  subject: "",
  emailScope: "",
  subMails: [],
  files: [],
};

const InitialPreviewModal = {
  recieverOrgList: [],
  sender: "",
  projectName: "",
  projectAdress: "",
  bidDateAndTime: "",
  contactPersonList: [],
  tradeList: [],
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  selectFieldList: string[];
  handleSubmit: (sendSubsInvit: SendSubsInviteType) => void;
  showAllMails?: boolean;
  isLoading?: boolean;
  previewFormData?: PreviewModalType;
  tradeId?: any;
  inviteTypeList: string | string[];
}

const BidInviteModal = ({
  isOpen = false,
  handleClose,
  selectFieldList = [],
  handleSubmit,
  showAllMails = false,
  isLoading = false,
  tradeId = undefined,
  inviteTypeList = "Bid Invite",
}: Props) => {
  const [minimize, setMinimize] = useState<boolean>(false);
  const [sendSubsInvit, setSendSubsInvit] =
    useState<SendSubsInviteType>(InitialSendSubsInvite);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [viewMailPreview, setViewMailPreview] = useState<boolean>(false);
  const [invitePreviewResponse, setInvitePreviewResponse] =
    useState<PreviewModalType>(InitialPreviewModal);

  useEffect(() => {
    if (!showAllMails) {
      setSendSubsInvit({
        ...sendSubsInvit,
        inviteType: inviteTypeList[0],
      });
    }
  }, [isOpen]);

  const color = useSelector(selectThemeColor);
  const projectId = useSelector(selectProjectId);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSendSubsInvit({
        ...sendSubsInvit,
        files: DataUtil.createAttachmentFile(files),
      });
      setSelectedFiles((prevRows: any) => [
        ...prevRows,
        ...DataUtil.createAttachmentFile(files),
      ]);
    }
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setSendSubsInvit({
      ...sendSubsInvit,
      [name]: value,
    });
  };

  const handleRemoveSelectedFile = (index: number) => {
    let removeSelectedFiles: File[] = [...selectedFiles].filter(
      (file: File, fileIndex: number) => fileIndex !== index
    );
    setSelectedFiles(removeSelectedFiles);
    setSendSubsInvit({
      ...sendSubsInvit,
      files: DataUtil.createAttachmentFile(removeSelectedFiles),
    });
  };

  const handleScopeChange = (content: string) => {
    setSendSubsInvit({
      ...sendSubsInvit,
      emailScope: content,
    });
  };

  const handleUpdateNewMails = (newEmail: string | string[]) => {
    if (typeof newEmail === "string") {
      const isEmailExist = sendSubsInvit.subMails.some(
        (selectedEmail) => selectedEmail === newEmail
      );
      if (!isEmailExist) {
        setSendSubsInvit({
          ...sendSubsInvit,
          subMails: [...sendSubsInvit.subMails, newEmail],
        });
      } else {
        setSendSubsInvit({
          ...sendSubsInvit,
          subMails: [
            ...sendSubsInvit.subMails.filter((email) => email !== newEmail),
          ],
        });
      }
    } else {
      setSendSubsInvit({
        ...sendSubsInvit,
        subMails: newEmail,
      });
    }
  };

  const handleOpenPreview = () => {
    if (!viewMailPreview) {
      getInvitePreview("", undefined, {
        projectId,
        tradeId: tradeId ? tradeId : null,
        subContractorMails: tradeId ? selectFieldList : [],
      })
        .then((resp: any) => {
          if (resp.data !== null && resp.data !== undefined) {
            const { data }: any = resp;
            setInvitePreviewResponse(data);
          } else {
            const message = resp.message || "Server not reachable.";
            alert(message);
            setInvitePreviewResponse(InitialPreviewModal);
          }
        })
        .catch(() => {
          setInvitePreviewResponse(InitialPreviewModal);
        });
    }
  };

  return (
    <Modal
      id="viewInvite"
      key="invite"
      className="position-fixed m-2"
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          position: "fixed",
          top: "auto",
          left: "auto",
          right: minimize ? "10px" : "0",
          bottom: minimize ? "10px" : "0",
          width: minimize ? "500px" : "500px",
          height: minimize ? "55px" : "535px",
          padding: minimize ? "0px" : "0",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          transition: "width 0.3s ease, height 0.3s ease",
        },
        overlay: {
          backgroundColor: minimize ? "transparent" : "rgba(0, 0, 0, 0.7)",
          zIndex: !minimize ? 0 : 1000,
          pointerEvents: !minimize ? "auto" : "none",
          transition: "background-color 0.3s ease, opacity 0.3s ease",
          opacity: 1,
        },
      }}
    >
      <div className="modal-dialog modal-2x" style={{ width: "500px" }}>
        <div className="modal-content justify-content-center">
          <div className="modal-header row gx-0">
            <div className="col-6">
              <h5 className="opacity-70">Invite Sub Contractors</h5>
            </div>
            <div className="col-4 offset-2">
              {!isLoading && (
                <>
                  <FontAwesomeIcon
                    className="col-2 offset-4 pointer"
                    icon={minimize ? faExpand : faMinus}
                    size="xl"
                    color={color.mediumColor}
                    onClick={() => setMinimize(!minimize)}
                  />
                  <FontAwesomeIcon
                    className="col-2 pointer"
                    icon={faClose}
                    size="2xl"
                    color={color.mediumColor}
                    onClick={() => {
                      setMinimize(false);
                      setSendSubsInvit(InitialSendSubsInvite);
                      setSelectedFiles([]);
                      handleClose();
                      setViewMailPreview(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {!minimize &&
            (viewMailPreview ? (
              <PreviewModal
                previewFormData={invitePreviewResponse}
                fillUpFormData={{
                  subject: sendSubsInvit.subject,
                  scope: sendSubsInvit.emailScope,
                  inviteType: sendSubsInvit.inviteType,
                }}
              />
            ) : (
              <div
                className="modal-body"
                style={{ width: "500px", height: "400px", overflowY: "scroll" }}
              >
                {inviteTypeList[0] === "Bid Invite" || showAllMails ? (
                  <div className="row m-1 mt-2">
                    <InputField
                      className="form-control bg-white col-12"
                      id="status"
                      type="text"
                      name="Template"
                      label="Template"
                      value={inviteTypeList[0]}
                      shrink
                      readonly
                    />
                  </div>
                ) : (
                  <div className="row gx-0">
                    <SelectInputField
                      id="status"
                      name="status"
                      defaultValue={inviteTypeList[0]}
                      formControlClassName="col-12"
                      selectClassName="m-3 bg-white"
                      labelClassName="m-3 bg-white"
                      menuClassName="d-block ms-2"
                      handleChange={(e : SelectChangeEvent<unknown>) =>
                        setSendSubsInvit({
                          ...sendSubsInvit,
                          inviteType: e.target.value as string,
                        })
                      }
                      label=""
                      menuList={inviteTypeList}
                    />
                  </div>
                )}

                {showAllMails ? (
                  <div className="row gx-0 mt-3">
                    <InviteSubDropdownField
                      selectedMails={sendSubsInvit.subMails}
                      handleClose={handleClose}
                      selectFieldList={selectFieldList}
                      handleUpdateNewMails={handleUpdateNewMails}
                    />
                  </div>
                ) : (
                  <div className="row m-1 mt-3">
                    <InputField
                      className="form-control bg-white col-12"
                      value={selectFieldList[0]}
                      id="email"
                      name="email"
                      type="text"
                      label="Invite Sub"
                      readonly
                      shrink
                    />
                  </div>
                )}
                <div className="row m-1 mt-3">
                  <InputField
                    className="form-control bg-white col-12"
                    id="subject"
                    type="text"
                    name="subject"
                    label="Subject"
                    handleChange={handleChange}
                  />
                </div>
                <div className="row m-2">
                  <ReactQuill
                    className="col-12"
                    theme="snow"
                    onChange={(content) => {
                      handleScopeChange(content);
                    }}
                    placeholder="Scope....."
                  />
                </div>
                <AttachFileList
                  selectedFiles={selectedFiles}
                  handleRemoveSelectedFile={handleRemoveSelectedFile}
                />
              </div>
            ))}
          {!minimize && (
            <div className="footer">
              <div className="row gx-0">
                <div className="col-6">
                  {!viewMailPreview && (
                    <Button
                      className="btn main-btn border ms-1"
                      component="label"
                      role={undefined}
                      variant="text"
                      tabIndex={-1}
                      size="small"
                    >
                      <AttachFileIcon className="fs-4" color="inherit" />
                      Attach files
                      <VisuallyHiddenInput
                        multiple
                        type="file"
                        onChange={handleFileChange}
                        onClick={handleFileInputClick}
                      />
                    </Button>
                  )}
                </div>
                <div
                  className={
                    !viewMailPreview
                      ? "col-4 offset-2 gap-2"
                      : "col-6 d-flex justify-content-end"
                  }
                >
                  <Button
                    className="btn main-btn"
                    onClick={() => {
                      setViewMailPreview(!viewMailPreview);
                      handleOpenPreview();
                    }}
                  >
                    {viewMailPreview ? "Cancel" : "Preview"}
                  </Button>
                  {!viewMailPreview && (
                    <LoaderButton
                      className="btn main-btn ms-2"
                      handleClick={() => {
                        handleSubmit(sendSubsInvit);
                        setSendSubsInvit(InitialSendSubsInvite);
                        setSelectedFiles([]);
                      }}
                      label="Send"
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BidInviteModal;

interface PreviewProps {
  previewFormData: PreviewModalType;
  fillUpFormData: any;
}

const PreviewModal = ({ previewFormData, fillUpFormData }: PreviewProps) => {
  const color = useSelector(selectThemeColor);

  return (
    <div
      className="modal-body"
      style={{ width: "500px", height: "400px", overflowY: "scroll" }}
    >
      <div className="header">
        <div className="row gx-0">
          <span className="col-2 offset-1">To:</span>
          <label className="col-7 offset-1">
            {previewFormData.recieverOrgList[0]}
          </label>
        </div>
        <div className="row gx-0">
          <span className="col-2 offset-1">From:</span>
          <label className="col-7 offset-1">
            {localStorage.getItem("orgName")}
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <h3 className="">{fillUpFormData.inviteType} to Bids</h3>
      </div>
      <div className="row gx-0">
        <span className="col-2 offset-1">Project:</span>
        <label className="col-7 offset-1">{previewFormData.projectName}</label>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Bid Date/Time</span>
        <label className="col-6">{previewFormData.bidDateAndTime}</label>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Project Address:</span>
        <label className="col-6">{previewFormData.projectAdress}</label>
      </div>
      <div className="row gx-0">
        <span className="col-2 offset-1">Trade:</span>
        <div className="col-8 offset-1">
          {previewFormData.tradeList.map((tradeData) => (
            <label className="d-block size-sm">{tradeData}</label>
          ))}
        </div>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Project Files:</span>
        <Button
          className="btn border col-3"
          style={{ background: color.darkColor, color: "white" }}
        >
          Bidwizard
        </Button>
      </div>
      <div className="row gx-0">
        <span className="col-3 offset-1">Contact Person:</span>
        <div className="col-8">
          {previewFormData.contactPersonList.length > 0 &&
            previewFormData.contactPersonList.map((person) => (
              <div className="d-block">
                <label htmlFor="">Name : {person.name}</label>
                <label htmlFor="">Email : {person.email}</label>
                <label htmlFor="">phoneNo: {person.phoneNo}</label>
              </div>
            ))}
        </div>
    
        <div className="row gx-0">
          <span className="col-2 offset-1">scope</span>
          <ReactQuill
            className="col-9"
            theme="snow"
            placeholder="Scope....."
            value={fillUpFormData.scope}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};
