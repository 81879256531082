import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import Modal from "react-modal";
import { ProjectDocumentHeaders, buttonGroupProject } from "../../utils/const";
import { useEffect, useState } from "react";
import { ProjectDocumentTable } from "../DataTable";
import { useSelector } from "react-redux";
import { getProjectDocuments } from "../../utils/api";
import { selectProjectId } from "../../redux/selectors/selectors";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  isLoading: boolean;
  handleAcceptRequest: (projectId: any, type:string) => void;
}

const PublicProjectViewModal = ({
  isOpen,
  handleClose,
  handleAcceptRequest,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState("Document");
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState(ProjectDocumentHeaders);

  const projectId = useSelector(selectProjectId);

  useEffect(() => {
    if (projectId) {
      fetchProjectDocApi("Document");
    }
  }, [isOpen]);

  const fetchProjectDocApi = (docType: string) => {
    getProjectDocuments("", { projectId, docType }, undefined)
      .then((response) => {
        if (response.status === "OK") {
          setRows(response.data);
        } else {
          setRows([]);
        }
      })
      .catch((error: any) => {
        setRows([]);
      });
  };

  const handlSwitchTab = (docType: any, headerCells: any) => {
    setSelectedTab(docType);
    setHeaders(headerCells);
    setRows([]);
    if (projectId && !!rows) {
      if (docType != "bidInfo" && docType != "qAndA") {
        fetchProjectDocApi(docType);
      }
    }
  };

  return (
    <Modal
      id="submitBid"
      isOpen={isOpen}
      style={{
        content: {
          width: "60vw",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          opacity: "1",
          background: "#f8f9fa",
          zIndex: "0",
        },
      }}
      ariaHideApp={false}
    >
      <div className="modal-header border-bottom">
        <div className="col-12 d-flex justify-content-end">
          <FontAwesomeIcon
            className="btn-close pointer"
            icon={faClose}
            size="lg"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </div>
      <div
        className="modal-body"
        style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="container">
          <div className="col-12 d-flex justify-content-between">
            {buttonGroupProject.map(
              (
                tab: { label: string; value: string; headers: any },
                index: number
              ) => {
                return ["bidInfo", "qAndA"].includes(tab.value) ? (
                  <></>
                ) : (
                  <Button
                    key={index}
                    className="col-3"
                    style={{
                      opacity: selectedTab === tab.value ? 1 : 0.7,
                    }}
                    onClick={() => {
                      handlSwitchTab(tab.value, tab.headers);
                    }}
                  >
                    {tab.label}
                  </Button>
                );
              }
            )}
          </div>
          <div className="row">
            <ProjectDocumentTable
              rows={rows}
              headers={headers}
              isEdit={false}
            />
          </div>
        </div>
      </div>
      <div className="row mt-1 gx-0">
        <div className="col-6">
          <Button
            className="btn col-12"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="col-6">
          <Button
            className="btn col-12"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleAcceptRequest(projectId,"view");
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PublicProjectViewModal;
