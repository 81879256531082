import {useEffect, useState } from "react";
import { CustomTable } from "../../DataTable";
import InputField from "../../InputField";
import { Button } from "@mui/material";
import {
  addPublicProjectToInvite,
  getAllSelectedTrades,
  getBidOppBySearch,
  getBidsExploreOpportunities,
  putPublicBidRequestAccess,
  updateOrgTrades,
} from "../../../utils/api";
import {
  selectProjectId,
} from "../../../redux/selectors/selectors";
import { useSelector } from "react-redux";
import { SearchFilterBidOppType } from "../../../utils/types";
import { useDispatch } from "react-redux";
import { setProjectId } from "../../../redux/actions/projectFormActions";
import PubicBidsOppTradeModal from "../../PubicBidsOppTradeModal/pubicBidsOppTradeModal";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import {
  faFilterCircleXmark,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataUtil } from "../../../utils/dataUtils";
import PublicProjectViewModal from "../../PublicProjectViewModal/publicProjectViewModal";

const DefaultSearchFiltered = {
  projectName: "",
  dueDateFrom: "",
  dueDateTo: "",
  email: "",
  city: "",
};

const BidExploreOpportunities = () => {
  const [exploreOpportunitiesRowData, setExploreOpportunitiesRowData] =
    useState<any>([]);
  const [search, setSearch] = useState<SearchFilterBidOppType>(
    DefaultSearchFiltered
  );
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [allTradesRows, setAllTradesRows] = useState<any>([]);
  const [isDueDate, setIsDueDate] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenPublicDocModal, setIsOpenPublicDocModal] =
    useState<boolean>(false);
  const [actionType, setActionType] = useState<("view" | "request" | "")>("");


  const dispatch = useDispatch();
  const projectId = useSelector(selectProjectId);

  const headCells = [
    { id: "projectName", name: "Project", action: "publicProjectHover", align: "left" },
    { id: "bidNumber", name: "Bid#", action: "", align: "left" },
    { id: "bidDate", name: "Bid Date", action: "date" },
    { id: "jobWalkDate", name: "Job Walk Date", action: "date" },
    { id: "city", name: "City", action: "", align: "left" },
    { id: "invitedBy", name: "Posted By", action: "", align: "left" },
    { id: "Request Access", name: "Action", action: "button" },
  ];

  useEffect(() => {
    fetchGetBidsExpApi();
  }, []);

  const fetchApiGetAllProjectTrades = (
    selectedProjectId: number,
    type: string
  ) => {
    setIsLoading(true);
    getAllSelectedTrades("", selectedProjectId, undefined)
      .then((resp) => {
        if (resp.data.AllTrades.length > 0) {
          setAllTradesRows(resp.data.AllTrades);
          const isAtleastOneTradeThere =
            resp.data.AllTrades.filter((data: any) => data.isAdded === "Y")
              .length > 0;
          setIsOpenModal(!isAtleastOneTradeThere);
          if (isAtleastOneTradeThere) {
            if (type === "request") {
              fetchPutPublicBidRequestAccess(selectedProjectId);
            } else if (type === "view") {
              fetchAddPublicProjectToInvite(selectedProjectId);
            }
          }
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchGetBidsExpApi = () => {
    getBidsExploreOpportunities(" ", undefined, undefined)
      .then((resp) => {
        if (
          resp.data === null ||
          resp.data === new Map() ||
          resp.data === undefined
        ) {
          setExploreOpportunitiesRowData([]);
        } else {
          setExploreOpportunitiesRowData(resp.data);
        }
      })
      .catch((e: any) => {
        console.log("e error:>> ", e);
      });
  };

  const fetchPutPublicBidRequestAccess = (selectedProjectId : number) => {
    putPublicBidRequestAccess("", selectedProjectId, undefined)
      .then((resp) => {
        if (resp.status === "OK") {
          fetchGetBidsExpApi();
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchAddPublicProjectToInvite = (selectedProjectId:number) =>{
    addPublicProjectToInvite("", undefined, {
      projectId : selectedProjectId,
      projectTradeId: null,
      subContractorMails: [""],
      status:"Accepted"
    })
      .then((response) => {
        setIsLoading(false);
        fetchGetBidsExpApi();
      })
      .catch((error: any) => {
        setIsOpen(true);
        setMessage("Invite has already been sent.");
        setIsLoading(false);
      });
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "dueDateFrom" && (value !== "" || value !== null)) {
      setIsDueDate(true);
    }
    if (name === "email") {
      const changedvalue = value.replace(/[^a-zA-Z0-9@.]/g, "").trim();
      setSearch({
        ...search,
        [name]: changedvalue,
      });
    } else if (name === "city") {
      const changedvalue = value.replace(/[^a-zA-Z0-9 ]/g, "").trim();
      setSearch({
        ...search,
        [name]: changedvalue,
      });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

  const handlePutRequestAccess = (
    row: any,
    actionType: string,
    checked: boolean
  ) => {
    dispatch(setProjectId(row.projectId));
    switch (actionType) {
      case "edit":
        setActionType(() => "request");
        fetchApiGetAllProjectTrades(row.projectId,"request");
        break;
      case "view":
        setActionType("view");
        setIsOpenPublicDocModal(true);
        break;
      default:
        setActionType("");
        break;
    }
  };

  const handleSubmitNotAddedTrades = (data: any) => {
    setIsLoading(true);
    updateOrgTrades("", projectId, data).then((resp) => {
      setIsOpenModal(false);
      if (actionType === "request") {
        fetchPutPublicBidRequestAccess(projectId);
      } else if (actionType === "view") {
        fetchAddPublicProjectToInvite(projectId);
      }
    });
  };

  const handleSearch = () => {
    getBidOppBySearch("", undefined, search).then((resp) => {
      if (resp.data === null || resp.data === new Map()) {
        setExploreOpportunitiesRowData([]);
      } else {
        setExploreOpportunitiesRowData(resp.data);
        setIsDueDate(false);
      }
    });
  };
  return (
    <>
      <div className="row gx-0">
        {/* Search Bar Component */}
        <div className="row mt-2">
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="projectName"
              name="projectName"
              type="text"
              className="form-control"
              label="Project"
              value={search.projectName}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="dueDateFrom"
              name="dueDateFrom"
              type="date"
              className="form-control"
              label="Bid Date (From)"
              shrink
              value={search.dueDateFrom}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="dueDateTo"
              name="dueDateTo"
              type="date"
              className="form-control"
              label="Bid Date (To)"
              shrink
              value={search.dueDateTo}
              handleChange={handleChange}
              required={isDueDate}
              disabled={
                search.dueDateFrom === null || search.dueDateFrom === ""
              }
            />
          </div>
          {/* <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="byGC"
              name="email"
              type="text"
              className="form-control"
              label="Posted By"
              value={search.email}
              handleChange={handleChange}
            />
          </div> */}
          <div className="col-2">
            <InputField
              id="city"
              name="city"
              type="text"
              className="form-control"
              label="City"
              value={search.city}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <Button
              className="btn mt-1"
              onClick={() => {
                setSearch(DefaultSearchFiltered);
                fetchGetBidsExpApi();
              }}
              title="Clear"
            >
              <FontAwesomeIcon icon={faFilterCircleXmark} size="lg" />
            </Button>
            <Button
              className="btn mt-1 ms-2"
              onClick={handleSearch}
              disabled={
                DataUtil.isObjectsAreEqual(search, DefaultSearchFiltered) ||
                (search.dueDateFrom !== "" && search.dueDateTo === "")
              }
              title="Search"
            >
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </Button>
          </div>
        </div>
        <div className="row">
          <CustomTable
            headCells={headCells}
            rows={exploreOpportunitiesRowData}
            onSelectRowClick={handlePutRequestAccess}
            isLoading={isLoading}
          />
        </div>
      </div>
      <PubicBidsOppTradeModal
        openModal={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        handleSubmitNotAddedTrades={handleSubmitNotAddedTrades}
        allTradesRows={allTradesRows}
      />
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handleCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
      <PublicProjectViewModal
        isOpen={isOpenPublicDocModal}
        handleClose={() => setIsOpenPublicDocModal(false)}
        isLoading={false}
        handleAcceptRequest={fetchApiGetAllProjectTrades}
      />
    </>
  );
};

export default BidExploreOpportunities;
