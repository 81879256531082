import { Button } from "@mui/material";
import Modal from "react-modal";
import InputField from "../InputField";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../redux/selectors/selectors";
import { PreviewModalType } from "../../utils/types";
import { getOrgSubsList } from "../../utils/api";
import { AddOrgSubsHeaderCells, ModalStyle } from "../../utils/const";
import UseFormikValidation from "../../hooks/useFormValidation";
import { AddSubValidationSchema } from "../../utils/validateSchemaUtil";
import { CustomTable } from "../DataTable";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderButton from "../Loader/loaderButton";
import { DataUtil } from "../../utils/dataUtils";


interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleUpdateNewMails?: (newMail: string) => void;
  isLoading?: boolean;
  previewFormData?: PreviewModalType;
  handleClickOnPreview?: (viewMailPreview: boolean) => void;
  tradeId?: any;
  inviteTypeList?: string[];
  node: any;
  handlAddToInviteSubList:  (subMails: string[]) => void
  invitedsubContractorList: any;
}

const AddSubsToInviteModal = ({
  isOpen,
  node,
  handleClose,
  handlAddToInviteSubList,
  isLoading=false,
  invitedsubContractorList = []
}: Props) => {
  const [isOrgSubListOpen, setIsOrgSubListOpen] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [inviteOrgEmail, setInviteOrgEmail] = useState<string[]>([]);

  const projectId = useSelector(selectProjectId);

  const handleToOpenOrgList = () => {
    setIsOrgSubListOpen(true);
    setInviteOrgEmail([]);
    getOrgSubsList("", { projectId, tradeId: node.id }, undefined)
      .then((response) => {
        if (response.data && response.data.selectedOrgTradeSubs) {
          let filteredOrgTradeSubs = response.data.selectedOrgTradeSubs;
          invitedsubContractorList.forEach((invitedMail: any) => {
            filteredOrgTradeSubs = filteredOrgTradeSubs.filter(
              (sub: { email: string }) => sub.email !== invitedMail.email
            );
          });
          filteredOrgTradeSubs = filteredOrgTradeSubs.map(
            (sub: { email: string }) => ({ ...sub, isSelected: false })
          );
          setRows(filteredOrgTradeSubs);
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleCheck = (row: any, checked: boolean) => {
    if (row.length > 1) {
      if (checked) {
        setInviteOrgEmail(rows.map((row) => row.email));
      } else {
        setInviteOrgEmail([]);
      }
    } else {
      const isEmailExist = inviteOrgEmail.some((email) => row.email === email);
      if (!isEmailExist) {
        setInviteOrgEmail([...inviteOrgEmail, row.email]);
      } else {
        setInviteOrgEmail(
          inviteOrgEmail.filter((email) => email !== row.email)
        );
      }
    }
  };

  return (
    <Modal
      id="addInviteSubContractor"
      isOpen={isOpen}
      style={ModalStyle}
      ariaHideApp={false}
    >
      <div className="modal-dialog modal-">
        <div
          className="modal-content"
          style={{
            height: isOrgSubListOpen ? "76vh" : "45vh",
            width: isOrgSubListOpen ? "60vw" : "40vw",
            overflow: "hidden",
          }}
        >
          <div className="modal-header border-bottom">
            <div className="row gx-0 col-12 p-0">
              <h4 className="col-11 opacity-70">
                Invite For Trade -  <strong>{node.tradeCode}</strong>
              </h4>
              <div className="col-1 d-flex justify-content-end">
                <FontAwesomeIcon
                  className="pointer "
                  icon={faClose}
                  size="xl"
                  onClick={() => {
                    if (!isLoading) {
                      handleClose();
                      setIsOrgSubListOpen(false);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <>
              {isOrgSubListOpen ? (
                <div className="row gx-0 mt-3">
                  <CustomTable
                    headCells={AddOrgSubsHeaderCells}
                    rows={rows}
                    handleCheck={handleCheck}
                    maxHeight={300}
                  />
                  <div className="row col-6 offset-3 mt-3 fixed-bottom">
                    <div className="col-6">
                      <Button
                        className="btn col-12"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!isLoading) {
                            setIsOrgSubListOpen(false);
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-6">
                      <LoaderButton
                        className="btn col-12"
                        isLoading={isLoading}
                        handleClick={() => {
                          setIsOrgSubListOpen(false);
                          handlAddToInviteSubList!(inviteOrgEmail);
                        }}
                        label="Add to List"
                        disabled={inviteOrgEmail.length === 0}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <NewSubsFormSection
                  node={node}
                  handleClose={() => {
                    handleClose();
                    setIsOrgSubListOpen(false);
                  }}
                  handleMoveToOrgSubsList={handleToOpenOrgList}
                  handlAddToInviteSubList={handlAddToInviteSubList}
                  isLoading={isLoading}
                />
              )}
            </>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSubsToInviteModal;

const NewSubsFormSection = ({
  node,
  handleClose,
  handleMoveToOrgSubsList,
  handlAddToInviteSubList,
  isLoading
}: any) => {

  const rows = DataUtil.isArrayAndNotEmpty(node.subsList) ? node.subsList : [];
  
  const initialValues = {
    orgName: "",
    firstName: "",
    mobileNo: "",
    email: "",
    response: "",
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    setFieldTouched,
    handleReset,
  } = UseFormikValidation({
    initialValues,
    validationSchema: AddSubValidationSchema(rows),
    validateOnMount: true,
  });

  return (
    <>
      <div className="container overflow-auto" style={{ height: "30vh" }}>
        <div className="row gx-0 mt-3">
          <InputField
            id="orgName"
            name="orgName"
            type="text"
            value={values.orgName}
            handleChange={handleChange}
            className="form-control"
            label="Organization Name"
            error={touched.orgName && errors.orgName}
            required
          />
        </div>
        <div className="row gx-0 mt-3">
          <InputField
            id="firstName"
            name="firstName"
            type="text"
            value={values.firstName}
            maxLength={25}
            handleChange={handleChange}
            className="form-control"
            label="Name"
            error={touched.firstName && errors.firstName}
            required
          />
        </div>
        <div className="row gx-0 mt-3">
          <InputField
            id="mobileNo"
            name="mobileNo"
            type="text"
            value={values.mobileNo.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
            maxLength={10}
            handleChange={handleChange}
            className="form-control"
            label="Phone Number"
            error={touched.mobileNo && errors.mobileNo}
            required
          />
        </div>
        <div className="row gx-0 mt-3">
          <InputField
            id="email"
            name="email"
            type="email"
            maxLength={50}
            value={values.email}
            handleChange={handleChange}
            className="form-control"
            label="Email"
            error={touched.email && errors.email}
            required
          />
        </div>
      </div>
      <div className="row gx-0 fixed-bottom mt-1">
        <div className="col-6 d-flex justify-content-end">
          <LoaderButton
            className="btn col-6"
            isLoading={isLoading}
            handleClick={() => {
              if (isValid) {
                handlAddToInviteSubList!([values.email]);
              } else {
                setFieldTouched("orgName", true);
                setFieldTouched("firstName", true);
                setFieldTouched("mobileNo", true);
                setFieldTouched("email", true);
              }
            }}
            label="Add To List"
          />
        </div>
        <div className="col-6 d-flex justify-content-start">
          <Button
            className="btn col-6"
            onClick={(e) => {
              e.preventDefault();
              handleMoveToOrgSubsList();
            }}
          >
            Add From Network
          </Button>
        </div>
      </div>
    </>
  );
};
