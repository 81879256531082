import { TableCell, createTheme, styled, tableCellClasses } from "@mui/material";

export const CustomeTheme = createTheme({
  palette: {
    primary: {
      light: "#19255c",
      main: "#19255c",
      dark: "#19255c",
    },
    secondary: {
      light: "#cfe0ff",
      main: "#cfe0ff",
      dark: "#cfe0ff",
    },
  },
  typography: {
    fontFamily: `SenticosansDTCond Thin`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px !important",
          opacity: 0.7,
          fontFamily: `SenticosansDTCond Thin`,
          textTransform: "capitalize",
          cursor: "pointer",
          backgroundColor: "#19255c !important",
          color: "white !important",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.8rem",
          fontWeight: 500,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          opacity: 0.5,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontFamily: "SenticosansDTCond Thin",
          opacity: 0.8,
          textTransform: "capitalize",
          padding: "12px 16px",
          color: "black",
          "&.Mui-selected": {
            color: "black",
            fontWeight: "bold",
          },
          "&:hover": {
            opacity: 1,
          },
          "&.Mui-disabled": {
            opacity: "0.6",
            background: "transparent",
            cursor: "not-allowed !important",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            height: '30%',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: '50vh',
          overflowY: 'auto',
        },
      },
    },
  },
  
});

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  width: '70%',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:"#5d678d",
    color: "#fff",
    fontSize: theme.typography.fontSize + 2,
    fontWeight: "600",
    padding: "0.7rem",
    width: "auto",
    minWidth: 100,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.fontSize - 1,
  },
}));

