import { createAction } from 'typesafe-actions';
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_COLOR_THEME = "SET_COLOR_THEME";
export const SET_TO_NAVIGATE_MENU = "SET_TO_NAVIGATE_MENU";

export const setActiveStepAction = createAction(
    SET_ACTIVE_STEP,
    (action) => (payload: any) => action(payload)
);

export const setColorTheme = createAction(
    SET_COLOR_THEME,
    (action) => (payload: any) => action(payload)
);

export const setToNavigateMenu = createAction(
    SET_TO_NAVIGATE_MENU,
    (action) => (payload: any) => action(payload)
);
