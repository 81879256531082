import { useEffect, useState } from "react";
import InputField from "../../InputField";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomTable } from "../../DataTable";
import { editOrganizationHeaderCells } from "../../../utils/const";
import { getOrgUserList, updateOrganization } from "../../../utils/api";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import CustomizedSnackbar from "../../CustomizedSnackbar/customizedSnackbar";

type Row = {
  id: any;
  first_name: string;
  email: string;
  phone_number: string;
  status: string;
};

type orgDetail = {
  orgName: string;
  licenseNumber: string;
  createdDate: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  status: string;
  usersOfOrganization: Row[];
};

const defOrgDetail = {
  orgName: "",
  licenseNumber: "",
  createdDate: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  status: "",
  usersOfOrganization: [],
};

const EditOrganization = (props: any) => {
  const [orgDetail, setorgDetail] = useState<orgDetail>(defOrgDetail);
  console.log("props values", props.values);
  const [selectedValue, setSelectedValue] = useState("active");
  const [rows, setRows] = useState<Row[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const orgName = props.values.orgName;
  const orgId = props.values.orgId;

  const color = useSelector(selectThemeColor);
  const style = [
    { width: "100%", overflowX: "hidden", overflowY: "scroll" },
  ];

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSelectedValue(value);
    props.setFieldValue("isActive", value === "active");
  };

  useEffect(() => {
    if (orgId) {
      getOrgUserList("", orgId, undefined).then((response) => {
        const userData = usersOfOrgList(response.data.usersOfOrganization);
        console.log("data org details", userData);
        console.log(" org details", response.data);
        setorgDetail(response.data);
        setSelectedValue(response.data.status === "Y" ? "active" : "inactive");
        setRows(userData);
      });
    }
  }, []);

  const usersOfOrgList = (data: any[]) => {
    return (
      data &&
      data.map((item: any) => ({
        id: item.id,
        first_name: item.firstName + " " + item.lastName,
        email: item.email,
        phone_number: item.mobileNo.replace(/\D/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
        status: item.status,
      }))
    );
  };

  const handleSave = () => {
    console.log("values", props.values);
    const status = props.values.isActive ? "Active" : "Inactive";
    console.log("status", status);
    if (orgId) {
      updateOrganization("", { orgId, status }, undefined)
        .then((response) => {
          console.log("edit org:", response);
          if (response.status === "OK") {
            setIsOpen(true);
            setMessage("Status updated successfully!");
            props.setBackButton(false);
          } else {
            setMessage(response.message);
            setIsOpen(true);
          }
        })
        .catch((error: any) => {
          setMessage(error.message);
          setIsOpen(true);
        });
    }
  };

  return (
    <>
      <div className="overflow-hidden p-1 mr-2" style={{width:"100%"}}>
        <div className="d-grid">
          <div
            className="container-fluid"
            style={{ backgroundColor: "#F8F9FA", width:"100%", padding:"0" ,height:"70vh", overflowY:'auto', overflowX:'hidden'}}
          >
            <div className="row justify-content-center mt-5">
              <div className="form-floating col-5 col-md-4 col-sm-4">
                <InputField
                  id="orgName"
                  name="orgName"
                  type="text"
                  value={orgDetail.orgName}
                  className="form-control"
                  label="Organization Name"
                  readonly
                />
              </div>
              <div className="form-floating col-5 col-md-4 col-sm-4">
                <InputField
                  id="licenseNumber"
                  name="licenseNumber"
                  type="text"
                  value={props.values.licenseNumber}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="License Number"
                  error={
                    props.isSubmit &&
                    props.touched.license_num &&
                    props.errors.license_num
                  }
                  readonly
                />
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="form-floating col-5 col-md-4 col-sm-4">
                <InputField
                  id="classification"
                  name="classification"
                  type="text"
                  value={props.values.classification}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Classification"
                  error={
                    props.isSubmit &&
                    props.touched.classification &&
                    props.errors.classification
                  }
                  readonly
                />
              </div>
              <div className="form-floating col-5 col-md-4 col-sm-4">
                <InputField
                  id="createdDate"
                  name="createdDate"
                  type="text"
                  value={props.values.createdDate}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Registration Date"
                  error={
                    props.isSubmit &&
                    props.touched.created_date &&
                    props.errors.created_date
                  }
                  readonly
                />
              </div>
            </div>
            <div className="row justify-content-center gap-1 mt-3">
              <div className="form-floating col-10 col-md-8 col-sm-8">
                <InputField
                  id="address1"
                  name="address1"
                  type="text"
                  value={orgDetail.address1}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Address (Line 1)"
                  readonly
                />
              </div>
              <div className="form-floating col-10 col-md-8 col-sm-8">
                <InputField
                  id="address2"
                  name="address2"
                  type="text"
                  value={orgDetail.address2}
                  handleChange={props.handleChange}
                  className="form-control"
                  label="Address (Line 2)"
                  error={
                    props.isSubmit &&
                    props.touched.address2 &&
                    props.errors.address2
                  }
                  readonly
                />
              </div>
            </div>
            <div className="row justify-content-center gap-1 mt-3">
              <div className="form-floating col-3 col-md-3 col-sm-3">
                <InputField
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  className="form-control"
                  value={orgDetail.zipCode}
                  label="Zip Code"
                  readonly
                />
              </div>
              <div className="form-floating col-3 col-md-3 col-sm-3">
                <InputField
                  id="state"
                  name="state"
                  type="text"
                  value={orgDetail.state}
                  className="form-control"
                  label="State"
                  readonly
                />
              </div>
              <div className="form-floating col-4 col-md-4 col-sm-4">
                <InputField
                  id="city"
                  name="city"
                  type="text"
                  value={orgDetail.city}
                  className="form-control"
                  label="City"
                  readonly
                />
              </div>
            </div>

            <div className="mt-2">
              <h4
                className="col-12 opacity-70 p-0 size-xl"
                style={{ marginLeft: "8%" }}
              >
                {orgDetail.orgName} Users:
              </h4>
            </div>
            <div className="row justify-content-center col-10 gx-1 offset-1">
              <CustomTable
                headCells={editOrganizationHeaderCells}
                rows={rows}
                style={style}
              />
            </div>
            <div className="row offset-5">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="active"
                  label="Active"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="inactive"
                  label="In-Active"
                  control={<Radio />}
                />
                {/* {props.isSubmit &&
                  props.touched.isActive &&
                  props.errors.isActive && (
                    <span className="text-danger">{props.errors.isActive}</span>
                  )} */}
              </RadioGroup>
            </div>
          </div>
          <div className="row justify-content-center mt-1 pb-2">
            <Button
              className="btn col-2 col-md-2 col-sm-2 mb-4 mt-3 ms-5"
              style={{ backgroundColor: color.darkColor, color: "white" }}
              onClick={(e) => {
                e.preventDefault();
                props.onSubmit();
                props.setBackButton(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn col-2 col-md-2 col-sm-2 mb-4 mt-3 ms-5"
              onClick={(e: any) => {
                e.preventDefault();
                handleSave();
              }}
              style={{ backgroundColor: color.darkColor, color: "white" }}
            >
              Submit
            </Button>
          </div>
        </div>
        <CustomizedSnackbar
          openSnackBar={isOpen}
          message={message}
          handleCloseSnackBar={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </>
  );
};

export default EditOrganization;
